'use client';
import { pushDummyStep, pushStepVariables } from '@/redux/flowSlice';
import { RootState } from '@/redux/store';
import { SelectChangeEvent } from '@mui/material';
import { errorNames, errorTypes, stepNames, steps, variables } from '@repo/onb-api';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetErrors } from './errorSlice';

type fieldProps = {
  name: keyof variables;
  defaultValue?: any;
  stepName?: stepNames;
  checkbox?: boolean;
  required?: boolean;
  errorHandler?: {
    type: 'text' | 'radioGroup' | 'checkbox' | 'select';
    errorText?: string;
    validate?: (value: string) => boolean;
  };
  beforeChange?: (value: any) => any; //@todo - narrow down types
  beforeRender?: (value: any) => any; //@todo - narrow down types
  onChange?: (
    e: React.SyntheticEvent | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<unknown>,
  ) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

/**
 * Generates a connected field object based on the given field properties.
 * @param {string} name - The name that will be used to access the value of the field in the step data.
 * @param {string} stepName - The name of the step.
 * @param {boolean} checkbox - A boolean to handle checkbox logic on change.
 * @param {any} defaultValue - The default value of the field.
 * @param {boolean} required - A boolean to handle required field cases.
 * @param {Function} beforeChange - A function that will be called before the value is changed.
 * @param {Function} beforeRender - A function that will be called before the value is rendered.
 * @param {Function} onChange - Chained parent function on change.
 * @param {Function} onBlur - Chained parent function on blur.
 * @param {Function} onClick - Chained parent function on click.
 * @return {Object} - The connected field properties.
 */
export const connectedField = (fieldProps: fieldProps) => {
  const {
    beforeChange,
    beforeRender,
    required,
    name,
    stepName,
    checkbox,
    errorHandler,
    onChange,
    onBlur,
    onClick,
    defaultValue,
  } = fieldProps;
  if (!stepName) return {};
  const dispatch = useDispatch();
  const flowdata = useSelector((state: RootState) => state.flowdata);
  const errorState = useSelector((state: RootState) => state.errorState);
  const stepData = flowdata.steps?.find((s) => s.name === stepName) as steps[typeof stepName];
  const [errorResult, setErrorResult] = useState({});
  const value = beforeRender
    ? beforeRender((stepData?.variables && isKeyOfVariables(name) && stepData?.variables?.[name]) || defaultValue || '')
    : (stepData?.variables && isKeyOfVariables(name) && stepData?.variables?.[name]) || defaultValue || '';

  function isKeyOfVariables(key: string): key is keyof typeof stepData.variables {
    return key in stepData.variables;
  }

  useEffect(() => {
    if (stepData?.variables && !isKeyOfVariables(name) && defaultValue) {
      dispatchData(defaultValue);
    }
  }, [stepData, name, defaultValue]);

  useEffect(() => {
    setErrorResult(
      name === errorState?.attribute && errorHandler?.errorText !== 'Código inválido' //@todo - REMOVE 2nd condition
        ? {
            error: true,
            helperText: errorTypes?.[errorState?.error as errorNames],
          }
        : {},
    );
  }, [name, errorState?.attribute, errorState?.error, errorHandler?.errorText]);

  useEffect(() => {
    if (errorState?.clearErrors) {
      setErrorResult({});
      dispatch(resetErrors());
    }
  }, [errorState]);

  const handleErrors = (blur: boolean = false) => {
    const visibleError = { error: true, helperText: errorHandler?.errorText };
    if (required && !value) {
      setErrorResult({
        'data-internalerror': true,
        ...(blur ? visibleError : {}),
      });
    } else if (errorHandler?.validate) {
      setErrorResult(
        errorHandler?.validate?.(value) === false
          ? {
              'data-internalerror': true,
              ...(blur ? visibleError : {}),
            }
          : {},
      );
    } else {
      setErrorResult({});
    }
  };

  const dispatchData = (value: any) => {
    if (!stepData) {
      dispatch(pushDummyStep(stepName));
    }
    dispatch(
      pushStepVariables({
        stepName,
        variable: name,
        value: beforeChange ? beforeChange(value) : value,
      }),
    );
  };

  return {
    value,
    required,
    ...errorResult,
    onChange: (
      e: React.MouseEvent | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<unknown>,
    ) => {
      dispatchData(
        checkbox ? `${(e.target as HTMLInputElement).checked ? 'true' : ''}` : (e.target as HTMLInputElement).value,
      );
      handleErrors();
      onChange?.(e);
    },
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
      if (e?.target?.value !== '') {
        handleErrors(true);
        onBlur?.(e);
      }
    },
    onClick: (e: any) => {
      setErrorResult({}), onClick?.(e);
    },
  };
};
