'use client';
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { variables } from '@repo/onb-api';
import { MaterialIcon } from '@repo/ui';
import { formatter } from '@repo/utils';
import { useState } from 'react';

const RowDetails = ({ label, value }: { label?: string; value?: string }): JSX.Element => {
  return (
    <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <Typography variant="body3">{label}</Typography>
      <Typography variant="body3">{value}</Typography>
    </Grid>
  );
};

export const ConfirmationDetails = ({
  termsAndConditions,
  requestedAmount,
  installmentCount,
  paymentSchedule,
  documentNumber,
  gender,
  fullName,
  birthDate,
  taxpayerId,
  email,
  mobilePhoneNumber,
  province,
  paymentMethod,
  bankAccountReference,
  installmentRates,
}: variables): JSX.Element => {
  const [accordionState, setAccordionState] = useState([false, false, false]);
  return (
    <Grid container sx={{ flexDirection: 'column', flexWrap: 'nowrap', height: '100%', gap: 2 }}>
      <Typography variant="body3">
        <div dangerouslySetInnerHTML={{ __html: termsAndConditions || '' }} />
      </Typography>
      <Divider />
      <Accordion
        disableGutters
        elevation={0}
        sx={{ '&:before': { display: 'none' }, border: 'none' }}
        expanded={accordionState[0]}
        onChange={() => setAccordionState([!accordionState[0], accordionState[1]!, accordionState[2]!])}
      >
        <AccordionSummary
          sx={{ p: 0 }}
          expandIcon={<MaterialIcon icon="keyboard_arrow_down" size={34} color="primary" />}
        >
          <Typography variant="body2">Detalles de la operación</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Grid container sx={{ flexDirection: 'column' }}>
            <RowDetails label="Préstamo elegido" value={formatter(`${requestedAmount}`, 'currency')} />
            <RowDetails label="Cantidad de cuotas" value={`${installmentCount}`} />
            <Typography variant="body3" sx={{ fontWeight: '500', mt: 2 }}>
              Vencimientos
            </Typography>
            {paymentSchedule?.map(({ number, dueDate, amount }) => (
              <RowDetails
                key={number}
                label={formatter(`${dueDate}`, 'date')}
                value={formatter(`${amount}`, 'currency')}
              />
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Divider />
      <Accordion
        disableGutters
        elevation={0}
        sx={{ '&:before': { display: 'none' } }}
        expanded={accordionState[1]}
        onChange={() => setAccordionState([accordionState[0]!, !accordionState[1], accordionState[2]!])}
      >
        <AccordionSummary
          sx={{ p: 0 }}
          expandIcon={<MaterialIcon icon="keyboard_arrow_down" size={34} color="primary" />}
        >
          <Typography variant="body2">Conocé los detalles de tu préstamo</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Grid container sx={{ flexDirection: 'column' }}>
            <Typography variant="body3" sx={{ fontWeight: '500' }}>
              Tus datos
            </Typography>
            <RowDetails label="Nombre y Apellido" value={fullName} />
            <RowDetails label="Número de documento" value={documentNumber} />
            <RowDetails label="Género" value={gender === 'MALE' ? 'Masculino' : 'Femenino'} />
            <RowDetails label="Fecha de nacimiento" value={formatter(`${birthDate}`, 'date')} />
            <RowDetails label="ID Tributaria" value={taxpayerId} />
            <RowDetails label="Categoría IVA" value={'Consumidor Final'} />
            <RowDetails label="Provincia" value={formatter(`${province}`, 'capitalize')} />
            <RowDetails label="Email" value={email} />
            <RowDetails label="Celular" value={mobilePhoneNumber} />
            <Typography variant="body3" sx={{ fontWeight: '500', mt: 2 }}>
              Condiciones de tu préstamo
            </Typography>
            <RowDetails label="Capital" value={formatter(`${requestedAmount}`, 'currency')} />
            <RowDetails label="Moneda" value={'Pesos'} />
            <RowDetails label="Cantidad de cuotas" value={`${installmentCount}`} />
            <RowDetails
              label="Valor de la cuota"
              value={`${formatter(paymentSchedule?.[0]?.amount || 0, 'currency')}`}
            />
            <RowDetails label="Sistema de amortización" value={'Francés'} />
            <RowDetails label="Monto neto" value={formatter(`${requestedAmount}`, 'currency')} />
            {Object.entries(installmentRates || {}).map(([key, value]) => (
              <RowDetails key={key} label={key} value={`${formatter(value, 'number')}%`} />
            ))}
            <RowDetails label="Método de pago" value={paymentMethod === 'DEBIT_CARD' ? 'Tarjeta' : 'Efectivo'} />
            <Typography variant="body3" sx={{ fontWeight: '500', mt: 2 }}>
              Forma de acreditación
            </Typography>
            <RowDetails label="Cuenta" value={bankAccountReference} />
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Divider />
      <Accordion
        disableGutters
        elevation={0}
        sx={{ '&:before': { display: 'none' } }}
        expanded={accordionState[2]}
        onChange={() => setAccordionState([accordionState[0]!, accordionState[1]!, !accordionState[2]])}
      >
        <AccordionSummary
          sx={{ p: 0 }}
          expandIcon={<MaterialIcon icon="keyboard_arrow_down" size={34} color="primary" />}
        >
          <Typography variant="body2">Términos y condiciones de tu préstamo</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Typography variant="body3">
            <div dangerouslySetInnerHTML={{ __html: termsAndConditions || '' }} />
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Divider />
      <Button variant="text" onClick={() => setAccordionState([false, false, false])}>
        Mostrar menos
      </Button>
    </Grid>
  );
};
