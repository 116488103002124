'use client';
import { sendOnbEvent } from '@/components/newRelic';
import { pushFlowData } from '@/redux/flowSlice';
import { pushErrorReason } from '@/redux/navigationSlice';
import { RootState } from '@/redux/store';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { getInstance } from '@repo/onb-api';
import { useRouter } from 'next/navigation';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StepWrapper from './stepWrapper';

export default function IdentityVerificationPending(): JSX.Element {
  const instanceId = useSelector((state: RootState) => state.flowdata.id);
  const router = useRouter();
  const dispatch = useDispatch();
  const iteration = useRef(0);
  const interval = setInterval(async () => {
    iteration.current += 1;
    if (iteration.current > 40) {
      clearInterval(interval);
      sendOnbEvent({
        type: 'error',
        subtype: 'metamap',
        severity: 'high',
        data: { stepName: 'IDENTITY_VERIFICATION_STATUS_PENDING', reason: 'timeout' },
      });
      dispatch(pushErrorReason('slowService'));
      router.push('/error');
    }
    const instanceData = await getInstance({ instanceId });
    if (instanceData?.steps?.[instanceData?.steps?.length - 1]?.name !== 'IDENTITY_VERIFICATION_STATUS_PENDING') {
      clearInterval(interval);
      dispatch(pushFlowData(instanceData));
    }
  }, 5000);
  return (
    <StepWrapper>
      <Stack alignItems="center" justifyContent="center" height="100%" gap={2}>
        <CircularProgress size={80} />
        <Typography variant="h1" textAlign={'center'}>
          Estamos verificando tu identidad, por favor esperá
        </Typography>
      </Stack>
    </StepWrapper>
  );
}
