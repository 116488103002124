'use client';
import { OnbForm } from '@/components/form';
import { connectedField } from '@/redux/fields';
import { RootState } from '@/redux/store';
import { TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { contactInformationRegistration, provinces } from '@repo/onb-api';
import { Dropdown } from '@repo/ui';
import { extractAreaCode, formatter, validAreaCode } from '@repo/utils';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StepWrapper from './stepWrapper';

export default function ContactInformationRegistration({
  stepdata: stepData,
  stepname: stepName,
}: {
  stepdata?: contactInformationRegistration['variables'];
  stepname?: contactInformationRegistration['name'];
}): JSX.Element {
  const firstName = useSelector((state: RootState) => state.flowdata.variables?.firstName);
  const { steps } = useSelector((state: RootState) => state.flowdata);
  const dispatch = useDispatch();
  const [phoneAreaCode, setPhoneAreaCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    const extractCode = extractAreaCode(stepData?.mobilePhoneNumber || '');
    if ((stepData?.mobilePhoneNumber?.length || 0) > 0) {
      if (extractCode && !phoneAreaCode && !phoneNumber) {
        setPhoneAreaCode(extractCode);
        setPhoneNumber(stepData?.mobilePhoneNumber?.slice(extractCode.length) || '');
      }
    }
  }, [dispatch, steps, stepData?.mobilePhoneNumber]);

  return (
    <StepWrapper>
      <Typography variant="h1">
        {firstName ? `${formatter(firstName, 'capitalize')}, necesitamos` : 'Necesitamos'} algunos datos para continuar
      </Typography>
      <Typography variant="h2">
        Te enviaremos un código a tu celular por SMS y por WhatsApp para que veas cuánto te podemos ofrecer.
      </Typography>
      <OnbForm<contactInformationRegistration> stepName={stepName!}>
        <TextField
          label="Ingresá tu correo electronico"
          autoFocus
          placeholder="Ej: ejemplo@dominio.com"
          slotProps={{ htmlInput: { inputMode: 'email', autoComplete: 'email', 'data-testid': 'email' } }}
          {...connectedField({
            stepName,
            name: 'email',
            required: true,
            errorHandler: {
              type: 'text',
              errorText: 'El correo ingresado no es válido',
              validate: (value) => /^[a-zA-Z\d.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/.test(value),
            },
          })}
        />
        <Grid container direction={'row'} wrap="nowrap" gap={2}>
          <Grid size={{ xs: 4 }}>
            <TextField
              label="Cód. Área"
              placeholder="Ej: 11 o 348"
              slotProps={{ htmlInput: { inputMode: 'numeric', 'data-testid': 'phoneAreaCode', maxLength: 4 } }}
              {...connectedField({
                beforeRender: () => phoneAreaCode || '',
                beforeChange: (value) => {
                  setPhoneAreaCode(value?.replaceAll(/\D+/g, ''));
                  return `${value?.replaceAll(/\D+/g, '') || ''}${phoneNumber || ''}`;
                },
                stepName,
                name: 'mobilePhoneNumber',
                required: true,
                errorHandler: {
                  type: 'text',
                  errorText: 'Código inválido',
                  validate: (value) => validAreaCode(value),
                },
              })}
            />
          </Grid>
          <Grid size={{ xs: 8 }}>
            <TextField
              label="Celular"
              placeholder="Ej: 1234567"
              slotProps={{
                htmlInput: { inputMode: 'numeric', 'data-testid': 'phoneNumber', maxLength: 10 - phoneAreaCode.length },
              }}
              {...connectedField({
                beforeRender: (value) => value?.slice(phoneAreaCode.length) || '',
                beforeChange: (value) => {
                  setPhoneNumber(value?.replaceAll(/\D+/g, ''));
                  return `${phoneAreaCode}${value?.replaceAll(/\D+/g, '')}`;
                },
                stepName,
                name: 'mobilePhoneNumber',
                required: true,
                errorHandler: {
                  type: 'text',
                  errorText: 'Ingresa un celular válido',
                  validate: (value) => value.length + phoneAreaCode.length === 10,
                },
              })}
            />
          </Grid>
        </Grid>
        <Dropdown
          data-testid="province"
          label="Provincia"
          placeholder="Ej: Buenos Aires"
          options={provinces.map((p) => ({ label: formatter(p, 'capitalize') as string, value: p }))}
          {...connectedField({
            stepName,
            name: 'province',
            required: true,
            beforeChange: (value) => provinces.find((p) => value === p) || '',
            errorHandler: {
              type: 'select',
              errorText: 'Selecciona una provincia',
            },
          })}
        />
        <Typography variant="subtitle1" textAlign={'center'} width={'80%'} alignSelf={'center'} my={2}>
          Al continuar declaro que soy mayor de edad y acepto los{' '}
          <a href="#terms-and-conditions" style={{ fontWeight: 'bold' }}>
            Términos y condiciones
          </a>{' '}
          y las{' '}
          <a href="#privacy-policy" style={{ fontWeight: 'bold' }}>
            Políticas de privacidad
          </a>
        </Typography>
      </OnbForm>
    </StepWrapper>
  );
}
