'use client';
import { RootState } from '@/redux/store';
import { Button, Stack, Typography } from '@mui/material';
import { Doodles, SafeSpacePadder } from '@repo/ui';
import { deleteClientCookie } from '@repo/utils';
import { useRouter } from 'next/navigation';
import { useSelector } from 'react-redux';

export default function Error() {
  const router = useRouter();
  const { errorReason } = useSelector((state: RootState) => state.navigationdata);

  return (
    <SafeSpacePadder
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center',
        gap: 4,
        height: '100%',
        width: '100%',
        paddingBottom: { xs: 4, md: 8 },
      }}
    >
      <Stack alignItems="center" gap={4} height={'100%'} justifyContent={'center'}>
        <Doodles type="end" />
        <Stack gap={2}>
          {errorReason === 'slowService' ? (
            <Typography variant="h1">El servicio está un poco lento, por favor reintentalo a la brevedad</Typography>
          ) : (
            <Typography variant="h1">Ups! Ha habido un error</Typography>
          )}
        </Stack>
      </Stack>
      <Stack gap={2} width={'100%'}>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            router.push('/');
            router.refresh();
          }}
        >
          Reintentar
        </Button>
        <Button
          fullWidth
          variant="inverted"
          onClick={() => {
            deleteClientCookie('instanceId');
            router.push('/');
            router.refresh();
          }}
        >
          Volver al Inicio
        </Button>
      </Stack>
    </SafeSpacePadder>
  );
}
