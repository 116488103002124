import { BackArrow } from '@/components/backarrow';
import Grid from '@mui/material/Grid2';
import { SafeSpacePadder, Transition } from '@repo/ui';

export default function StepWrapper({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element {
  return (
    <Transition id="step_wrapper_transition" style={{ display: 'flex', width: '100%', height: '100%' }}>
      <SafeSpacePadder>
        <Grid container sx={{ flexDirection: 'column', flexWrap: 'nowrap', height: '100%', gap: 2 }}>
          <BackArrow />
          {children}
        </Grid>
      </SafeSpacePadder>
    </Transition>
  );
}
