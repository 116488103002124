'use client';
import { OnbForm } from '@/components/form';
import { RootState } from '@/redux/store';
import { Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { identityVerificationStart } from '@repo/onb-api';
import { Ekyc, MaterialIcon, Metamap } from '@repo/ui';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import StepWrapper from './stepWrapper';

export default function IdentityVerification({
  stepname: stepName,
  stepdata: stepData,
}: {
  stepname: identityVerificationStart['name'];
  stepdata?: identityVerificationStart['variables'];
}): JSX.Element {
  const submitRef = useRef<HTMLButtonElement>(null);
  const instanceId = useSelector((state: RootState) => state.flowdata.id);
  return (
    <StepWrapper>
      <Typography variant="h1">¡Genial! Ahora vamos a validar tu identidad</Typography>
      <Typography variant="h2">
        Buscá tu DNI. Vamos a necesitar fotos del frente y dorso. Podés seguír estos tips:
      </Typography>
      <Stack sx={{ py: 1 }}>
        <Ekyc />
      </Stack>
      <Stack gap={3} sx={{ pb: 2 }}>
        <Grid container sx={{ gap: 3 }} direction={'row'} wrap="nowrap">
          <MaterialIcon color="primary" containerType="circle" icon="flash_off" sx={{ lineHeight: '1 !important' }} />
          <Typography variant="body2" color={'primary.main'}>
            Evitá usar flash para que los datos sean legibles. La foto no debe tener reflejos.
          </Typography>
        </Grid>
        <Grid container sx={{ gap: 3 }} direction={'row'} wrap="nowrap">
          <MaterialIcon
            color="primary"
            containerType="circle"
            icon="photo_camera"
            sx={{ lineHeight: '1 !important' }}
          />
          <Typography variant="body2" color={'primary.main'}>
            Encuadrá tu DNI para que coincida con el marco y salga completo.
          </Typography>
        </Grid>
        <Grid container sx={{ gap: 3 }} direction={'row'} wrap="nowrap">
          <MaterialIcon color="primary" containerType="circle" icon="cached" sx={{ lineHeight: '1 !important' }} />
          <Typography variant="body2" color={'primary.main'}>
            Girá tu celular para lograr una foto más cercana y nítida.
          </Typography>
        </Grid>
      </Stack>
      <OnbForm<identityVerificationStart> stepName={stepName} hideSubmit sx={{ justifyContent: 'flex-end' }}>
        <Stack>
          <button ref={submitRef} style={{ display: 'none' }}></button>
          <Metamap
            clientId={stepData?.clientId}
            flowId={stepData?.flowId}
            identityId={stepData?.identityId}
            traceId={stepData?.traceId}
            instanceId={instanceId}
            callback={() => {
              submitRef.current?.click();
            }}
          />
        </Stack>
      </OnbForm>
    </StepWrapper>
  );
}
